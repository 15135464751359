import fetch from "../auth/FetchInterceptor";

const WorkspaceUserService = {}

WorkspaceUserService.getAllWorkspaceUser = function () {
    return fetch({
        url: '/WorkspaceUser/get-all-workspace-user',
        method: 'get'
    })
}

WorkspaceUserService.deleteAllExceptWorkspaceUser = function (data) {
    return fetch({
        url: '/WorkspaceUser/delete-all-except-workspace-user',
        method: 'delete',
        data: data
    })
}

WorkspaceUserService.getAllWorkspaceUserSubscription = function () {
    return fetch({
        url: '/WorkspaceUser/get-all-workspace-user-subscriptions',
        method: 'get'
    })
}

export default WorkspaceUserService;