import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WorkspaceUserService from "../../services/WorkspaceUserService";

export const getAllWorkspaceUser = createAsyncThunk('get-all-workspace-user', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.getAllWorkspaceUser();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const deleteAllExceptWorkspaceUser = createAsyncThunk('delete-all-except-workspace-user', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.deleteAllExceptWorkspaceUser(data);
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

export const getAllWorkspaceUserSubscription = createAsyncThunk('get-all-workspace-user-subscription', async (data, { rejectWithValue }) => {
    try {
        const response = await WorkspaceUserService.getAllWorkspaceUserSubscription();
        return response;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
});

const WorkspaceUserSlice = createSlice({
    name: 'workspaceUserSlice',
    initialState: {
        workspaceUser: [],
        workspaceUserSubscription: [],
        loading: false,
        error: null,
    },
    reducers: {

    },
    extraReducers: (builder)=> {
        builder
            .addCase(getAllWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(getAllWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAllWorkspaceUserSubscription.pending, (state)=> {
                state.loading = true;
            })
            .addCase(getAllWorkspaceUserSubscription.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUserSubscription = action.payload;
            })
            .addCase(getAllWorkspaceUserSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(deleteAllExceptWorkspaceUser.pending, (state)=> {
                state.loading = true;
            })
            .addCase(deleteAllExceptWorkspaceUser.fulfilled, (state, action) => {
                state.loading = false
                state.workspaceUser = action.payload;
            })
            .addCase(deleteAllExceptWorkspaceUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },

});

export default WorkspaceUserSlice.reducer;